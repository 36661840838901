import { Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { BookingFormComponent } from './components/booking-form/booking-form.component';
import { CancellationFormComponent } from './components/cancellation-form/cancellation-form.component';

export const routes: Routes = [
    { path: 'unauthorized', redirectTo: '' },
    { path: 'booking', redirectTo: '' },
    { path: '', component: BookingFormComponent },
    { path: 'cancellation', component: CancellationFormComponent },
    { 
        path: '',
        loadChildren: () => import('./components/private/private.routes'), 
        canLoad: [AutoLoginPartialRoutesGuard] 
    }
];
