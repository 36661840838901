import { registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import localeDe from '@angular/common/locales/de-CH';
import localeFr from '@angular/common/locales/fr-CH';
import localeIt from '@angular/common/locales/it-CH';
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withHashLocation } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { authInterceptor, provideAuth } from 'angular-auth-oidc-client';
import { ApiConfig, jsonInterceptor, MultipleHttpTranslateLoader } from 'common';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { authConfig } from './auth/auth.config';

registerLocaleData(localeDe, 'de-CH');
registerLocaleData(localeFr, 'fr-CH');
registerLocaleData(localeIt, 'it-CH');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withHashLocation()),
    provideAuth(authConfig),
    provideHttpClient(
      withInterceptors([
        jsonInterceptor,
        authInterceptor()
      ])
    ),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'fr-CH',
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => new MultipleHttpTranslateLoader(http, [
            { prefix: "./assets/i18n/map/", suffix: ".json"},
            { prefix: "./assets/i18n/", suffix: ".json" },
          ]),
          deps: [HttpClient]
        }
      })
    ),
    provideAnimations(),
    { provide: ApiConfig, useValue: environment.apiConfig },
    { provide: LOCALE_ID, useValue: "fr-CH" }
  ]
};


