<div class="row">
    <div class="col-4 mx-auto">
        <form (ngSubmit)="onSubmit()" [formGroup]="bxpEventForm">
            <div class="mb-3">
                <label class="form-label">{{ "booking-form.bxp.labels.participate" | translate }}</label>
                <div>
                    <input type="radio" class="btn-check" id="radioParticipateY" [value]="true" formControlName="participating">
                    <label class="btn btn-outline-primary me-2" for="radioParticipateY">{{ "global.yes" | translate }}</label>
                    <input type="radio" class="btn-check" id="radioParticipateF" [value]="false" formControlName="participating">
                    <label class="btn btn-outline-primary" for="radioParticipateF">{{ "global.no" | translate }}</label>
                </div>
            </div>

            <div class="mb-3">
                <label class="form-label">{{ "booking-form.bxp.labels.accompanied" | translate }}</label>
                <div>
                    <input type="radio" class="btn-check" id="radioAccompaniedY" [value]="true" formControlName="accompanied">
                    <label class="btn btn-outline-primary me-2" for="radioAccompaniedY">{{ "global.yes" | translate }}</label>
                    <input type="radio" class="btn-check" id="radioAccompaniedF" [value]="false" formControlName="accompanied">
                    <label class="btn btn-outline-primary" for="radioAccompaniedF">{{ "global.no" | translate }}</label>
                </div>
            </div>

            @if(bxpEventForm.value.accompanied) {
                <div class="mb-3">
                    <label for="inputAccompanying" class="form-label">{{ "booking-form.bxp.labels.accompanying" | translate }}</label>
                    <input type="text" id="inputAccompanying" class="form-control" formControlName="accompanying">
                </div>
            }

            <div class="mb-3">
                <label class="form-label">{{ "booking-form.bxp.labels.lang" | translate }}</label>
                <div>
                    @for (language of slot.languages.split(","); track $index) {
                        <input type="radio" class="btn-check" [id]="$index" [value]="language" formControlName="language">
                        <label class="btn btn-outline-primary" [htmlFor]="$index">{{ "global.language." + language | translate }}</label>
                    }
                </div>
            </div>

            <button class="btn btn-secondary" type="submit">{{ "global.button.validate" | translate}}</button>
        </form>
    </div>
</div>