import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ApiConfig } from 'common';

@Component({
  selector: 'app-bxp-code-input',
  standalone: true,
  imports: [TranslateModule, FormsModule],
  templateUrl: './bxp-code-input.component.html',
  styleUrl: './bxp-code-input.component.scss'
})
export class BxpCodeInputComponent {

  code: string;
  isCodeInvalid: boolean;

  @Output()
  onCodeValidated = new EventEmitter<string>();

  constructor(
    private httpClient: HttpClient,
    private apiConfig: ApiConfig
  ) {}

  onCodeSubmitted() {
    if (this.code) {
    this.httpClient.post<boolean>(this.apiConfig.bookingService + '/access-code/check', this.code)
      .subscribe(resp => {
        if (resp) {
          this.onCodeValidated.emit(this.code);
        } else {
          this.isCodeInvalid = true;
        }
      });
    }
  }

}
