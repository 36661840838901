@if(bxpCode || isAuthenticated) {
    <mcdo-booking-form 
        [stores]="stores"
        [storeTooltipExtendedTemplate]="storeTooltipTemplate"
        (onSlotSelected)="onSlotSelected($event)"
        (onStoreSelected)="onStoreSelected($event)"
        [secondStepCompleteSubject]="onBxpEventValidate"
        [secondStepLabelTemplate]="secondStepLabel"
        (onContactSet)="bxpEvent.contact = $event"
        [eventRecapTemplate]="recapTemplate"
        (onConfirm)="onBookingConfirmed()"
        [isConfirmed]="isSubmitted"
        confirmationImage="/assets/images/open_door_logo.webp">

        @if(currentSlot) {
            <app-bxp-booking [slot]="currentSlot" [bxpEvent]="bxpEvent" (onValidate)="onBxpEventValidate.next($event)"></app-bxp-booking>
        }
        
    </mcdo-booking-form>
} @else {
    <app-bxp-code-input (onCodeValidated)="bxpCode = $event"></app-bxp-code-input>
}

<ng-template #secondStepLabel> 
    <i class="bi bi-person-fill h1 text-primary"></i>
</ng-template>

<ng-template #storeTooltipTemplate>
    @if(nextAvailableDates && nextAvailableDates.length !== 0) {
        <div>
            <i class="bi bi-calendar3"></i>
            @for (date of nextAvailableDates; track $index;) {
                {{ date | date:'dd.MM.y' }}{{ $last? '': ',' }}
            }
        </div>
    }
</ng-template>

<ng-template #recapTemplate>
    @if(eventComplete && bxpEvent.contact && currentStore) {
        <app-bxp-recap [event]="bxpEvent" [store]="currentStore"></app-bxp-recap>
    }
</ng-template>