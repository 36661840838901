import { Component } from '@angular/core';
import { BookingCancellationFormComponent } from 'common';
import { BxpRecapComponent } from '../common/bxp-recap/bxp-recap.component';

@Component({
  selector: 'app-cancellation-form',
  standalone: true,
  imports: [BookingCancellationFormComponent, BxpRecapComponent],
  templateUrl: './cancellation-form.component.html',
  styleUrl: './cancellation-form.component.scss'
})
export class CancellationFormComponent { }
