<div class="row border rounded p-3">
    <div class="col-md-6">
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.restaurant' | translate }}</label>
            </div>
            <div class="col-8"> {{ store.publicName }} </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label"> {{ 'recap.label.date' | translate }}</label>
            </div>
            <div class="col-8"> {{ event.date | date:'dd.MM.y' }} </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label"> {{ 'recap.label.slot' | translate }}</label>
            </div>
            <div class="col-8"> {{ event.date | date:'HH:mm' }} - {{ event.endDate | date:'HH:mm' }} </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label"> {{ 'recap.label.language' | translate }}</label>
            </div>
            <div class="col-8"> {{ 'global.language.' + event.language | translate }} </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="row">
            <div class="col-4">
                <label class="form-label"> {{ 'recap.label.contact.name' | translate }}</label>
            </div>
            <div class="col-8"> {{ event.contact?.firstName }} {{ event.contact?.lastName }} </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label"> {{ 'recap.label.contact.phone' | translate }}</label>
            </div>
            <div class="col-8"> {{ event.contact?.phoneNumber }} </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label"> {{ 'recap.label.contact.email' | translate }}</label>
            </div>
            <div class="col-8"> {{ event.contact?.email }} </div>
        </div>
        @if(event.accompanying) {
            <div class="row">
                <div class="col-4">
                    <label class="form-label"> {{ 'recap.label.accompanying' | translate }}</label>
                </div>
        
                <div class="col-8"> {{ event.accompanying }} </div>
            </div>
        }
    </div>
</div>