<div class="row">
    <div class="col-6 mx-auto text-center">
        <img src="/assets/images/open_door_logo.webp" style="width: 50%;">
        <p class="fs-4">{{ "booking-form.bxp-code-input.text" | translate }}</p>
        
        <input class="form-control" type="text" [(ngModel)]="code">

        @if(isCodeInvalid) {
            <div class="text-danger">{{ "booking-form.bxp-code-input.invalid" | translate }}</div>   
        }
   
        <button class="btn btn-secondary mt-3" (click)="onCodeSubmitted()">{{ "booking-form.bxp-code-input.button" | translate }}</button>
    </div>
</div>
