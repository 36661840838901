import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AvailableBookingSlot } from 'common';
import { BxpEvent } from '../../../data/BxpEvent';

@Component({
  selector: 'app-bxp-booking',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule],
  templateUrl: './bxp-booking.component.html',
  styleUrl: './bxp-booking.component.scss'
})
export class BxpBookingComponent {

  @Input({ required: true })
  slot: AvailableBookingSlot

  @Input({ required: true })
  bxpEvent: BxpEvent

  @Output()
  onValidate = new EventEmitter<boolean>()

  bxpEventForm = this.fb.group({
    participating: this.fb.control<boolean>(null, {validators: Validators.required}),
    accompanied: this.fb.control<boolean>(null, {validators: Validators.required}),
    accompanying: this.fb.control<string>(null),
    language: this.fb.control<string>(null, { validators: Validators.required })
  });

  constructor(
    private fb: FormBuilder
  ) { }

  onSubmit() {
    if (this.bxpEventForm.valid) {
      this.bxpEvent.accompanying = this.bxpEventForm.value.accompanying;
      this.bxpEvent.language = this.bxpEventForm.value.language;
    }

    this.onValidate.emit(this.bxpEventForm.valid);
  }
}
